// IO Frontend/src/services/api.js
import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const shortlinkApi = {
  // Create new shortlink
  create: async (formData) => {
    const response = await api.post('/api/shortlinks', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  },

  // Get user's shortlinks
  getMyLinks: async () => {
    const response = await api.get('/api/shortlinks/my-links');
    return response.data;
  },

  // Get specific shortlink stats
  getStats: async (shortId) => {
    const response = await api.get(`/api/shortlinks/stats/${shortId}`);
    return response.data;
  },

  // Complete task for shortlink
  completeTask: async (shortId, taskId) => {
    const response = await api.post(`/api/shortlinks/${shortId}/complete`, { taskId });
    return response.data;
  },

  // Update shortlink stats
  updateStats: async (shortId, type) => {
    const response = await api.post(`/shortlinks/${shortId}/stats`, { type });
    return response.data;
  }
};

export const authApi = {
  login: async (usernameOrEmail, password) => {
    const response = await api.post('/api/users/login', { usernameOrEmail, password });
    return response.data;
  },
  register: async (username, email, password) => {
    const response = await api.post('/api/users/register', { username, email, password });
    return response.data;
  },
  getCurrentUser: async () => {
    const response = await api.get('/api/users/me');
    return response.data;
  },
  logout: async () => {
    const response = await api.post('/api/users/logout');
    return response.data;
  },
  checkAuthStatus: async () => {
    const response = await api.get('/api/users/auth-status');
    return response.data;
  }
};

// Error interceptor
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;