// IO Frontend/src/components/ProtectedRoute.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config'; // Importiere die Konfigurationsdatei
import { ClipLoader } from 'react-spinners'; // Ladeanimation importieren

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        // Check auth status first
        const authResponse = await fetch(`${config.API_URL}/api/users/auth-status`, {
          credentials: 'include'
        });

        if (!authResponse.ok) {
          throw new Error('Not authenticated');
        }

        // Then fetch user details
        const userResponse = await fetch(`${config.API_URL}/api/users/me`, {
          credentials: 'include'
        });

        if (userResponse.ok) {
          setIsVerified(true);
        } else {
          throw new Error('User data not found');
        }
      } catch (error) {
        console.error('Auth verification failed:', error);
        setIsVerified(false);
        navigate('/');
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); // Mindestladezeit von 2 Sekunden
      }
    };

    verifyAuth();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader size={50} color={"#123abc"} loading={isLoading} />
      </div>
    );
  }

  return isVerified ? children : null;
};

export default ProtectedRoute;