import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FiHome, FiUser, FiSettings, FiLogOut, FiPlusCircle } from "react-icons/fi";
import { GoSidebarExpand, GoSidebarCollapse } from "react-icons/go"; // Neues Icon importieren
import { toast } from 'react-toastify';
import ShortlinkForm from './components/ShortlinkForm';
import styles from "./css/Home.module.css";
import { authApi, shortlinkApi } from './services/api';
import config from './config'; // Importiere die Konfigurationsdatei

const Home = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: ''
  });
  const [shortlinks, setShortlinks] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const data = await authApi.getCurrentUser();
        setUserData({
          name: data.username,
          email: data.email
        });
      } catch (error) {
        setIsAuthenticated(false);
        navigate('/');
      }
    };

    const fetchShortlinks = async () => {
      try {
        const data = await shortlinkApi.getMyLinks();
        setShortlinks(data);
      } catch (error) {
        toast.error('Serverfehler beim Abrufen der Shortlinks');
      }
    };

    fetchUserDetails();
    fetchShortlinks();
  }, [navigate, setIsAuthenticated]);

  const handleCreateSuccess = (newLink) => {
    setShortlinks([newLink, ...shortlinks]);
    setShowCreateForm(false);
    toast.success('Shortlink erfolgreich erstellt!');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.API_URL}/api/users/logout`, {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        setIsAuthenticated(false);
        navigate("/");
      } else {
        toast.error('Fehler beim Logout');
      }
    } catch (error) {
      console.error("Fehler beim Verbinden mit dem Server:", error);
      toast.error('Serverfehler beim Logout');
    }
  };

  return (
    <div className="relative flex h-screen">
      {/* Sidebar */}
      <div
        className={`${styles.sidebar} ${
          isSidebarOpen ? styles["sidebar-expanded"] : ""
        }`}
      >
        <div className={styles["sidebar-top"]}>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className={`${styles["sidebar-icon"]} ${
              isSidebarOpen ? styles["sidebar-toggle"] : ""
            }`}
          >
            {isSidebarOpen ? <GoSidebarExpand size={20} /> : <GoSidebarCollapse size={20} />}
          </button>
          <div className={styles["sidebar-icon"]}>
            <FiHome size={20} />
            {isSidebarOpen && <span className={styles["sidebar-text"]}>Home</span>}
          </div>
          <div className={styles["sidebar-icon"]}>
            <FiUser size={20} />
            {isSidebarOpen && (
              <span className={styles["sidebar-text"]}>Profile</span>
            )}
          </div>
          <div className={styles["sidebar-icon"]}>
            <FiSettings size={20} />
            {isSidebarOpen && (
              <span className={styles["sidebar-text"]}>Settings</span>
            )}
          </div>
          <Link to="/create-shortlink" className={styles["sidebar-icon"]}>
            <FiPlusCircle />
            {isSidebarOpen && <span className={styles["sidebar-text"]}>Link erstellen</span>}
          </Link>
        </div>
        <div className={`${styles["sidebar-bottom"]}`}>
          <div
            className={`${styles.logout} ${styles["sidebar-icon"]}`}
            onClick={handleLogout}
          >
            <FiLogOut size={20} />
            {isSidebarOpen && <span className={styles["sidebar-text"]}>Logout</span>}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={styles["main-content"]}>
        <div className={styles.header}>
          <h1>Willkommen {userData.name ? userData.name : "auf der Website"}!</h1>
        </div>

        <div className={styles["bento-grid"]}>
          <div className={styles["bento-box"]}>Box 1</div>
          <div className={styles["bento-box"]}>Box 2</div>
          <div className={styles["bento-box"]}>Box 3</div>
          <div className={styles["bento-box"]}>Box 4</div>
        </div>

        <div className="container mx-auto p-4">
          {/* Dashboard Header */}
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Meine Shortlinks</h1>
            <button
              onClick={() => setShowCreateForm(true)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded"
            >
              Neuen Shortlink erstellen
            </button>
          </div>

          {/* Shortlink erstellen */}
          {showCreateForm && (
            <ShortlinkForm
              onSuccess={handleCreateSuccess}
              onCancel={() => setShowCreateForm(false)}
            />
          )}

          {/* Shortlink Liste */}
          <div className="grid gap-4">
            {shortlinks.map((link) => (
              <div key={link.id} className="p-4 bg-white shadow rounded">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-lg font-semibold">{link.title}</h2>
                    <p className="text-gray-600">
                      {`${window.location.origin}/s/${link.shortId}`}
                    </p>
                  </div>
                  <div className="text-gray-600">
                    Klicks: {link.clicks || 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;