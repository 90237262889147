import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import Home from "./home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute';
import { authApi } from './services/api';
import CreateShortlink from './components/CreateShortlink';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Authentifizierungsstatus beim Laden prüfen
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const data = await authApi.checkAuthStatus();
        setIsAuthenticated(data.isAuthenticated);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuthStatus();
  }, []);

  // Beim Laden den Local Storage prüfen
  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm setIsAuthenticated={setIsAuthenticated} />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Home setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-shortlink"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <CreateShortlink />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        theme="dark"
        toastStyle={{
          backgroundColor: 'rgba(3, 3, 5, 0.5)',
          color: '#ffffff',
        }}
      />
    </Router>
  );
}

export default App;