import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Hier korrekt importieren
import { shortlinkApi } from '../services/api';

const CreateShortlink = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    url: '',
    title: '',
    description: '',
    tasks: [],
    image: null,
    youtubeUrl: '',
    adsEnabled: true,
  });

  const handleNext = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('url', formData.url);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('adsEnabled', formData.adsEnabled);
    if (formData.youtubeUrl) {
      formDataToSend.append('youtubeUrl', formData.youtubeUrl);
    }
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }
    // Tasks hinzufügen
    formData.tasks.forEach((task, index) => {
      formDataToSend.append(`tasks[${index}]`, task);
    });

    try {
      await shortlinkApi.create(formDataToSend);
      navigate('/home');
    } catch (error) {
      console.error('Fehler beim Erstellen des Shortlinks:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Neuen Shortlink erstellen</h1>
      {step === 1 && (
        <div>
          <label className="block mb-2">Ziel-URL eingeben:</label>
          <input
            type="url"
            name="url"
            value={formData.url}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-4"
            required
          />
          <button onClick={handleNext} className="px-4 py-2 bg-blue-600 text-white rounded">
            Weiter
          </button>
        </div>
      )}
      {step === 2 && (
        <div>
          <label className="block mb-2">Titel:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-4"
            required
          />

          <label className="block mb-2">Beschreibung:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-4"
          ></textarea>

          {/* Optional: Bild hochladen */}
          <label className="block mb-2">Bild hochladen (optional):</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
            className="mb-4"
          />

          {/* Optional: YouTube Video URL */}
          <label className="block mb-2">YouTube Video URL (optional):</label>
          <input
            type="url"
            name="youtubeUrl"
            value={formData.youtubeUrl}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-4"
          />

          <div className="flex justify-between">
            <button onClick={handlePrevious} className="px-4 py-2 bg-gray-300 rounded">
              Zurück
            </button>
            <button onClick={handleNext} className="px-4 py-2 bg-blue-600 text-white rounded">
              Weiter
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div>
          <label className="block mb-2">Aufgaben hinzufügen:</label>
          {/* Hier kannst du eine Funktion zum Hinzufügen mehrerer Aufgaben implementieren */}
          <input
            type="text"
            name="tasks"
            placeholder="Aufgabe eingeben"
            value={formData.tasks[0] || ''}
            onChange={(e) => setFormData({ ...formData, tasks: [e.target.value] })}
            className="w-full p-2 border rounded mb-4"
          />

          <div className="flex justify-between">
            <button onClick={handlePrevious} className="px-4 py-2 bg-gray-300 rounded">
              Zurück
            </button>
            <button onClick={handleNext} className="px-4 py-2 bg-blue-600 text-white rounded">
              Weiter
            </button>
          </div>
        </div>
      )}
      {step === 4 && (
        <div>
          <label className="block mb-2">Werbung anzeigen:</label>
          <input
            type="checkbox"
            name="adsEnabled"
            checked={formData.adsEnabled}
            onChange={handleInputChange}
            className="mb-4"
          />
          {!formData.adsEnabled && (
            <div className="mb-4">
              <p className="text-red-500">Werbefreiheit ist ein Premium-Feature.</p>
              {/* Placeholder für Premium-Kauf */}
              <button
                onClick={() => alert('Premium-Feature noch nicht verfügbar')}
                className="px-4 py-2 bg-green-600 text-white rounded"
              >
                Premium kaufen
              </button>
            </div>
          )}
          <div className="flex justify-between">
            <button onClick={handlePrevious} className="px-4 py-2 bg-gray-300 rounded">
              Zurück
            </button>
            <button onClick={handleSubmit} className="px-4 py-2 bg-green-600 text-white rounded">
              Fertigstellen
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateShortlink;