import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc"; // Neues Google-Icon importieren
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styles from "../css/LoginForm.module.css"; // Importiere das CSS
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authApi } from '../services/api';
import config from '../config'; // Importiere die Konfigurationsdatei

const LoginForm = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    usernameOrEmail: '', // Changed from email to usernameOrEmail
    password: '',
    username: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isLogin) {
        await authApi.login(formData.usernameOrEmail, formData.password);
        toast.success('Login erfolgreich! Willkommen zurück!');
      } else {
        await authApi.register(formData.username, formData.usernameOrEmail, formData.password);
        toast.success('Registrierung erfolgreich! Du wurdest automatisch eingeloggt.');
      }
      setIsAuthenticated(true);
      navigate('/home');
    } catch (err) {
      toast.error(err.response?.data?.error || 'Anmeldung fehlgeschlagen');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${config.API_URL}/auth/google`; // Verwende die URL aus der Konfigurationsdatei
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 via-gray-900 to-black">
      {/* Glassmorphism Container */}
      <div className={`relative w-full max-w-sm p-6 ${styles.glassContainer}`}>
        {/* Switch für Login und Registrieren */}
        <div className="flex justify-center mb-6 border-b border-gray-700">
          <button
            onClick={() => setIsLogin(true)}
            className={`w-1/2 py-2 text-center ${
              isLogin
                ? "font-bold text-white border-b-2 border-white"
                : "text-gray-400"
            }`}
          >
            Login
          </button>
          <button
            onClick={() => setIsLogin(false)}
            className={`w-1/2 py-2 text-center ${
              !isLogin
                ? "font-bold text-white border-b-2 border-white"
                : "text-gray-400"
            }`}
          >
            Registrieren
          </button>
        </div>

        {/* Formular */}
        <form onSubmit={handleSubmit}>
          {isLogin && (
            <div className="mb-4">
              <input
                type="text"
                value={formData.usernameOrEmail}
                onChange={(e) => setFormData({ ...formData, usernameOrEmail: e.target.value })}
                placeholder="E-Mail oder Benutzername"
                className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring focus:ring-primary"
              />
            </div>
          )}

          {!isLogin && (
            <>
              <div className="mb-4">
                <input
                  type="text"
                  value={formData.username}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                  placeholder="Benutzername"
                  className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring focus:ring-primary"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  value={formData.usernameOrEmail}
                  onChange={(e) => setFormData({ ...formData, usernameOrEmail: e.target.value })}
                  placeholder="E-Mail"
                  className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring focus:ring-primary"
                />
              </div>
            </>
          )}

          <div className="mb-4 relative">
            <input
              type={passwordVisible ? "text" : "password"}
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              placeholder="Passwort"
              className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring focus:ring-primary"
            />
            <div
              className="absolute top-3 right-3 text-gray-500 cursor-pointer"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          {!isLogin && (
            <div className="mb-4 relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                placeholder="Passwort bestätigen"
                className="w-full px-4 py-2 border border-gray-600 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring focus:ring-primary"
              />
              <div
                className="absolute top-3 right-3 text-gray-500 cursor-pointer"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              >
                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          )}

          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary text-white rounded-lg hover:bg-purple-700 transition"
          >
            {isLogin ? "Login" : "Registrieren"}
          </button>

          <div className="flex items-center my-4">
            <hr className="flex-grow border-gray-600" />
            <span className="mx-2 text-gray-500 text-sm">Oder</span>
            <hr className="flex-grow border-gray-600" />
          </div>

          {/* Google Login Button */}
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full mt-2 flex items-center justify-center bg-gray-700 border border-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition"
          >
            <FcGoogle className="mr-2 text-xl" /> {/* Neues Google Icon */}
            Mit Google anmelden
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;