// IO Frontend/src/components/ShortlinkForm.jsx
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import config from '../config'; // Importiere die Konfigurationsdatei

const ShortlinkForm = ({ onSuccess, onCancel }) => {
  const [title, setTitle] = useState('');
  const [destinationUrl, setDestinationUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/api/shortlinks`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ title, destinationUrl }),
      });
      if (response.ok) {
        const data = await response.json();
        onSuccess(data.shortlink);
        toast.success('Shortlink erstellt');
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Fehler beim Erstellen des Shortlinks');
      }
    } catch (error) {
      console.error('Error creating shortlink:', error);
      toast.error('Serverfehler beim Erstellen des Shortlinks');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4 p-4 bg-gray-100 rounded">
      <div className="mb-2">
        <label className="block text-gray-700">Titel</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block text-gray-700">Ziel-URL</label>
        <input
          type="url"
          className="w-full p-2 border rounded"
          value={destinationUrl}
          onChange={(e) => setDestinationUrl(e.target.value)}
          required
        />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onCancel}
          className="mr-2 px-4 py-2 text-gray-700"
        >
          Abbrechen
        </button>
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
          Erstellen
        </button>
      </div>
    </form>
  );
};

export default ShortlinkForm;